import React, { useState } from "react"

export const ThemeContext = React.createContext({
  name: "dark",
  updateTheme: () => { },
})

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("dark")
  if(theme === "dark") {
    document.body.classList.remove('light');
    document.body.classList.add('dark');
  } 

  if(theme === "light") {
    document.body.classList.remove('dark');
    document.body.classList.add('light');
  }
  
  return (
    <ThemeContext.Provider
    
      value={{
        name: theme,
        updateTheme: setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// import "prismjs/themes/prism.css"

import React from "react"
import { ThemeProvider } from "./src/components/ThemeContext"
export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)